import React from "react"

import Header from '../components/header'
import StartPage from '../components/startpage'
import Footer from '../components/footer'
import Seo from '../components/seo'
import{TinyButton as ScrollUpButton} from "react-scroll-up-button";
import {graphql} from 'gatsby'

// import 'bootstrap/dist/css/bootstrap.min.css';





class IndexPage extends React.Component {
  render() {


    return (
      <div>
        <Seo canonical={`${this.props.pageContext.baseurl}${this.props.path}`} metainfo={this.props.data.drupal.metainfo.entities[0].entityMetatags} />
         <Header contactinfo={this.props.pageContext.contactinfo.entities[0]} langcode={this.props.pageContext.langcode} langcontainer={this.props.pageContext.langcontainer}  path={this.props.path} menu={this.props.pageContext.menu}/>

        <StartPage langcode={this.props.pageContext.langcode} priceslink={this.props.data.drupal.priceslink.entities[0]}
          eventslink={this.props.data.drupal.eventslink.entities[0]}
          banner={this.props.data.drupal.banner.entities[0]}
          contactlink={this.props.data.drupal.contactlink.entities[0]} events={this.props.data.drupal.events.entities}
           testimonials={this.props.data.drupal.testimonials.entities} homeinfo={this.props.data.drupal.homeinfo.entities} offers={this.props.data.drupal.offers.entities}/>
        <Footer social={this.props.pageContext.social} langcode={this.props.pageContext.langcode}/>
        <ScrollUpButton ContainerClassName="scrollcontainer"  style={{color:'white'}} />
      </div>
    )
  }
}

export default IndexPage


export const query = graphql`
  query($langcode: DRUPAL_LanguageId) {
    drupal {
      events: nodeQuery(filter: {conditions: [{field: "type", value: "event", language:$langcode}]}, limit:3, sort: [{field: "field_event_starting_date"}]) {
      entities(language:$langcode) {

       ... on DRUPAL_NodeEvent {
         title
         entityId
         entityLanguage {
           id
         }
         body {
           processed
         }
         fieldEventDates
         fieldEventPlace
         fieldEventPrice
         fieldEventFacebookLink
         fieldEventImage {
           entity {
             thumbnail {
               url
               alt
               imageFile {
                 childImageSharp {
                   fluid(quality: 80,maxWidth: 500, maxHeight:400) {
                     ...GatsbyImageSharpFluid_withWebp
                     src
                   }
                 }
               }
             }
           }
         }
       }

      }
      }


      offers:nodeQuery(filter: {conditions: [{field: "type", value: "offer", language:$langcode}]}) {
        entities(language:$langcode) {

            ... on DRUPAL_NodeOffer {
              entityId
              title
              entityLanguage {
                id
              }
              fieldOfferPrice
              fieldOfferImage {
                entity {
                  thumbnail {
                    url
                    alt
                    imageFile {
                      childImageSharp {
                        fluid(quality: 80,maxWidth: 500) {
                          ...GatsbyImageSharpFluid_withWebp
                          src
                        }
                      }
                    }
                  }
                }
              }
            }

        }
      }


      testimonials: nodeQuery(filter: {conditions: [{field: "type", value: "testimonial",language:$langcode}]}) {
        entities(language:$langcode) {

            ... on DRUPAL_NodeTestimonial {
              entityLanguage{
                id
              }
              entityId
              title
              body {
                processed
              }
              fieldSubName
            }

        }
      }

      homeinfo: nodeQuery(filter: {conditions: [{field: "type", value: "home", language:$langcode}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodeHome {

      body {
        processed
      }

    }
  }
}

banner: nodeQuery(filter: {conditions: [{field: "type", value: "banner", language:$langcode}]}) {
 entities(language:$langcode) {
   ... on DRUPAL_NodeBanner {
     body {
           processed
         }
         fieldButton {
          title
          url {
            path
          }
        }
     fieldBannerImage {
       entity {
         thumbnail {
           url
           alt
           imageFile {
             childImageSharp {
               fluid( quality: 80, maxWidth:3920) {
                 ...GatsbyImageSharpFluid_withWebp
                 src
               }
             }
           }
         }
       }
     }
   }
 }
}

      contactlink: nodeQuery(filter: {conditions: [{field: "type", value: "contact", language:$langcode}]}) {
      entities(language:$langcode) {

      ... on DRUPAL_NodeContact {


       entityUrl {
         path
       }
      }

      }
      }

      eventslink: nodeQuery(filter: {conditions: [{field: "type", value: "events_list", language:$langcode}]}) {
      entities(language:$langcode) {

      ... on DRUPAL_NodeEventsList {


      entityUrl {
        path
      }

      }

      }
      }

      priceslink: nodeQuery(filter: {conditions: [{field: "type", value: "page",language:$langcode}]}) {
        entities(language:$langcode) {

            ... on DRUPAL_NodePage {





              entityUrl {
                path
              }

            }

        }
      }

      metainfo:nodeQuery(filter: {conditions: [{field: "type", value: "home"}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodeHome {

      entityMetatags {
        value
        key
      }

    }
  }
}


}
  }
`
