import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
     dots: false,
     arrows:true,
     infinite: true,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
     centerMode: true,
     centerPadding: 0
   };

const Testimonials = (props) => {
  return (
    <section  className="testimonials ys-section vc_row wpb_row vc_row-fluid vc_custom_1542623562030 vc_row-has-fill vc_row-no-padding vc_general vc_parallax vc_parallax-content-moving">
         <div  className="container container-big wide2">
           <div className="wpb_column vc_column_container vc_col-sm-12">
             <div className="vc_column-inner ">
               <div className="wpb_wrapper">
                 <div className="wpb_content_element testimonials_block type-3 py-6">


                      <h3 style={{color:'#ffffff'}} className="text-center">
                        {props.title}
                      </h3>


                      <div className="carousel-type-2 text-color-light testimonial-holder style-3">
                        <div className="icon-divider align-center"><i className="svg divider1"></i></div>
                        <Slider arrows={false} responsive={[
{
 breakpoint: 1024,
 settings: {
   slidesToShow: 2,

   infinite: true,
   dots: false
 }
},
{
 breakpoint: 600,
 settings: {
   slidesToShow: 1,

 }
},
{
 breakpoint: 480,
 settings: {
   slidesToShow: 1,
   slidesToScroll: 1
 }
}
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object
]} {...settings} >


     {props.testimonials.map((item) => {
       return (
         <div key={item.entityId} className="testcontainer testimonial">
            <blockquote dangerouslySetInnerHTML={{ __html: item.body.processed }} className="m-3 p-3 bg-white arrow_box2">
            </blockquote>
            <div className="author-box">

                    <span className="author-icon"><i className="svg"></i></span>
                    <div className="author-info">
                    <div style={{fontWeight:'bold', color:'white'}} className="author">{item.title}</div>
                    <span  className="author-position" style={{color:"#cfcfcf"}}>{item.fieldSubName}</span>
                    </div>

                  </div>

         </div>
       )
     })}

















 </Slider>

                      </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
    </section>
  )
}

export default Testimonials
