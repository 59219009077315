import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Testimonials from './testimonials'
import Img from 'gatsby-image'
import {Link} from 'gatsby'



class StartPage extends React.Component {



  render() {
    return (
      <main className="mainpage">
      <div  className="centralimagecontainer">
      <Img style={{minHeight:"400px"}}  fluid={this.props.banner.fieldBannerImage.entity.thumbnail.imageFile.childImageSharp.fluid} alt={this.props.banner.fieldBannerImage.entity.thumbnail.alt}/>
      <div className="maintitle">
         <div dangerouslySetInnerHTML={{ __html: this.props.banner.body.processed }}></div>


      <Link  to={this.props.banner.fieldButton.url.path}><button className="btn">{this.props.banner.fieldButton.title}</button></Link>

      </div>

      </div>
      <section className="fourimages">
           <Container fluid={true} className="p-0">
             <div data-vc-stretch-content="true" className="ys-section vc_row wpb_row vc_row-fluid vc_row-no-padding">
<div className="wpb_column vc_column_container vc_col-sm-12">
<div className="vc_column-inner ">
<div className="wpb_wrapper">
  <div className="wpb_content_element ys-icon-boxes">


    <Row noGutters={true} className="icons-box type1 style-2">
      <Col md={4} sm={12}>
        <div className="ys-icon-boxes-item icons-wrap box1">


          <div className="icons-item p-md-2 p-1">
          <div className="item-box">


              <i className="svg mudra-anjali"></i>


                          <h5 className="icons-box-title">
                            <Link to={`/${this.props.langcode.toLowerCase()}/about-us`}>
                              {this.props.langcode === "EN"? "About our Studio" : "Λίγα λόγια για εμάς"}
                            </Link>


              </h5>

            <div className="hidden-area">
                          <p>{this.props.langcode === "EN"? "Read more about our Mission, our Teachers & photos from our place." : "Δείτε φωτογραφίες από τον χώρο και αναλυτικές πληροφορίες για τους εκπαιδευτές."}</p>
                          <Link className="btn btn-style-2" to={`/${this.props.langcode.toLowerCase()}/about-us`}>
                            {this.props.langcode === "EN"? "READ MORE" : "ΠΕΡΙΣΣΟΤΕΡΑ"}
                          </Link>

                        </div>

          </div>
          </div>

        </div>
      </Col>
      <Col md={4} sm={12}>
        <div className="ys-icon-boxes-item icons-wrap box2">


          <div className="icons-item p-md-2 p-1">
          <div className="item-box">


              <i className="svg clock"></i>


                          <h5 className="icons-box-title">
                            <Link to={`/${this.props.langcode.toLowerCase()}/schedule`}>
                              {this.props.langcode === "EN"? "Weekly Programme" : "Εβδομαδιαίο Πρόγραμμα"}
                            </Link>


              </h5>

            <div className="hidden-area">
                          <p>{this.props.langcode === "EN"? "See our weekly schedule." : "Δείτε το Αναλυτικό εβδομαδιαίο πρόγραμμα μαθημάτων."}</p>

                          <Link className="btn btn-style-2" to={`/${this.props.langcode.toLowerCase()}/schedule`}>
                            {this.props.langcode === "EN"? "READ MORE" : "ΠΕΡΙΣΣΟΤΕΡΑ"}
                          </Link>
                        </div>

          </div>
          </div>

        </div>
      </Col>
      <Col md={4} sm={12}>
        <div className="ys-icon-boxes-item icons-wrap box3">


          <div className="icons-item p-md-2 p-1">
          <div className="item-box">


              <i className="svg asana-warrior"></i>


                          <h5 className="icons-box-title">
                            <Link to={`/${this.props.langcode.toLowerCase()}/events`}>
                              {this.props.langcode === "EN"? "UpComing Events" : "Νέα Events"}
                            </Link>


              </h5>

            <div className="hidden-area">
                          <p>{this.props.langcode === "EN"? "See all our Events and more info about each one." : "Δείτε όλα τα Events και πληροφορίες για το καθένα."}</p>

                          <Link className="btn btn-style-2" to={`/${this.props.langcode.toLowerCase()}/events`}>
                            {this.props.langcode === "EN"? "READ MORE" : "ΠΕΡΙΣΣΟΤΕΡΑ"}
                          </Link>
                        </div>

          </div>
          </div>

        </div>
      </Col>










</Row>


  </div>

  </div>

</div>

</div>

</div>
           </Container>
      </section>

      <section  className="flower">
         <Container className="">
             <div className="py-6 text-center">
            <h3 className="">"MIND BODY SOUL YOGA"</h3>
              <div className="icon-divider align-center mb-5"><i className="svg divider1"></i></div>
              <div dangerouslySetInnerHTML={{ __html: this.props.homeinfo[0].body.processed }}></div>

              </div>
         </Container>

      </section>


     <Testimonials title={this.props.langcode === "EN"? "TESTIMONIALS" : "ΕΙΠΑΝ ΓΙΑ ΕΜΑΣ"} testimonials={this.props.testimonials}/>

     <section className="special-offers">
         <Container className="container-big">
             <div className="py-6">
               <h3 className="text-center">{this.props.langcode === "EN"? "SPECIAL OFFERS" : "ΠΡΟΣΦΟΡΕΣ"}</h3>
               <p className="text-center">
                 {this.props.langcode === "EN"? "See our best Offers. For more offers you can visit the pricing page.":"Βρείτε μια προσφορά που ταιριάζει στις δικές σας απαιτήσεις από την αναλυτική λίστα με τα διάφορα πακέτα μαθημάτων."}
               </p>
                 <Row>
                   {this.props.offers.map((item) => {
                     return (
                       <Col md={4} sm={12} key={item.entityId}  className="text-center mb-3">
                               <div style={{backgroundImage:`linear-gradient(180deg, rgba(187, 220, 180, 0.86) 0%, rgba(54, 120, 102, 0.92) 100%), url(${item.fieldOfferImage.entity.thumbnail.imageFile.childImageSharp.fluid.src})`,backgroundSize:"cover"}} className="spec-box  d-flex flex-column align-items-center justify-content-center p-3">
                                       <h3>
                                          {item.title}
                                       </h3>


                                       <div className="position-absolute pricetransform">
                                           <strong>{item.fieldOfferPrice}</strong>
                                       </div>

                               </div>
                       </Col>
                     )
                   })}




                 </Row>
                 <div className="buttonend text-center mt-4">
                   <Link to={this.props.priceslink.entityUrl.path}>
                   <button className="btn">{this.props.langcode === "EN"? "MORE OFFERS" : "ΠΕΡΙΣΣΟΤΕΡΕΣ ΠΡΟΣΦΟΡΕΣ"}</button>
                   </Link>
                 </div>
             </div>
         </Container>
     </section>

      <section className="upevents">
        <Container>
           <div className="py-6">
              <h3 className="text-center">{this.props.langcode === "EN"? "LATEST EVENTS" : "ΤΕΛΕΥΤΑΙΑ EVENTS"}</h3>
              <Row>
                {this.props.events.map((item)=> {
                  if(!item){
                    return
                  }
                  return (
                    <Col md={4} sm={12}  key={item.entityId} className="mb-3">
                      <div style={{minHeight:'400px'}} className="bg-white p-3 h-100">
                        <div><Img style={{maxWidth:"100%"}} fluid={item.fieldEventImage.entity.thumbnail.imageFile.childImageSharp.fluid} alt={item.fieldEventImage.entity.thumbnail.alt}/></div>
                        <h5 className="mt-3">{item.title}</h5>
                        <div className="wrapper">
                       <span> <i style={{color: "#e883ae"}} className="far fa-clock"></i> {item.fieldEventDates}</span>
                       </div>
                       <div className="wrapper">
                       <span> <i style={{color: "#e883ae"}} className=" fas fa-map-marker-alt"></i> {item.fieldEventPlace}</span>
                       </div>


                        <div className="d-flex justify-content-between align-items-center mt-3 flex-wrap">
                          <span className="product-price">{item.fieldEventPrice}</span>
                          <a rel="noopener noreferrer" target="_blank" href={item.fieldEventFacebookLink}>
                           <button className="btn">{this.props.langcode === "EN"? "More Info" : "ΠΕΡΙΣΣΟΤΕΡΑ"}</button>
                           </a>

                        </div>

                      </div>
                    </Col>
                  )
                })}



              </Row>
              <div className="buttonend text-center mt-4">
                <Link to={this.props.eventslink.entityUrl.path}>
                <button className="btn">{this.props.langcode === "EN"? "More events" : "ΠΕΡΙΣΣΟΤΕΡΑ EVENTS"}</button>
                </Link>
              </div>
           </div>

        </Container>
      </section>

    </main>

    )
  }
}

export default StartPage

